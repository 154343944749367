@import url('https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700;800;900&display=swap');
:root {

/* Colors: */
--color-5e5e5e: #5e5e5e;
--color-feffef: #FEFFEF;
--color-fbad2a: #FBAD2A;
--color-3f60f5: #3f60f5;
--color-2ba0f0: #2BA0F0;
--color-253d61: #253D61;
--color-f1f4f8: #F1F4F8;
--color-e6ebed: #E6EBED;
--color-cdcdd2: #CDCDD2;
--color-a1a1aa: #A1A1AA;
--color-75797b: #75797B;
--color-52636f: #52636F;
--color-191f23: #191F23;
--color-fa5a68: #FA5A68;
--color-fcc15f: #FCC15F;
--color-59c697: #59C697;
--color-ffffff: #FFFFFF;

/* Font/text values */
--font-family-inter:'Inter', sans-serif;
--font-style-normal: normal;
--font-weight-600: 600;
--font-weight-medium: 500;
--font-weight-normal: normal;
--font-weight-bold: bold;
--font-size-13: 13px;
--font-size-14: 14px;
--font-size-16: 16px;
--font-size-18: 18px;
--font-size-20: 20px;
--font-size-22: 22px;
--font-size-24: 24px;
--font-size-28: 28px;
--font-size-38: 38px;
--font-size-69: 69px;
--character-spacing-0: 0px;
--line-spacing-24: 24px;
--line-spacing-26: 26px;
--line-spacing-29: 29px;
--line-spacing-30: 30px;
--line-spacing-45: 45px;
--line-spacing-46: 46px;
}

/* Character Styles */
.character-style-1 {
font-family: var(--font-family-inter);
font-style: var(--font-style-normal);
font-weight: var(--font-weight-bold);
font-size: var(--font-size-38);
line-height: var(--line-spacing-46);
letter-spacing: var(--character-spacing-0);
color: var(--color-191f23);
}
.character-style-2 {
font-family: var(--font-family-inter);
font-style: var(--font-style-normal);
font-weight: var(--font-weight-bold);
font-size: var(--font-size-28);
line-height: var(--line-spacing-45);
letter-spacing: var(--character-spacing-0);
color: var(--color-191f23);
}
.character-style-3 {
font-family: var(--font-family-inter);
font-style: var(--font-style-normal);
font-weight: var(--font-weight-bold);
font-size: var(--font-size-24);
line-height: var(--line-spacing-29);
letter-spacing: var(--character-spacing-0);
color: var(--color-191f23);
}
.character-style-4 {
font-family: var(--font-family-inter);
font-style: var(--font-style-normal);
font-weight: var(--font-weight-bold);
font-size: var(--font-size-22);
line-height: var(--line-spacing-26);
letter-spacing: var(--character-spacing-0);
color: var(--color-191f23);
}
.character-style-5 {
font-family: var(--font-family-inter);
font-style: var(--font-style-normal);
font-weight: var(--font-weight-normal);
font-size: var(--font-size-18);
line-height: var(--line-spacing-30);
letter-spacing: var(--character-spacing-0);
color: var(--color-75797b);
}
.character-style-6 {
font-family: var(--font-family-inter);
font-style: var(--font-style-normal);
font-weight: var(--font-weight-bold);
font-size: var(--font-size-14);
line-height: var(--line-spacing-24);
letter-spacing: var(--character-spacing-0);
color: var(--color-ffffff);
}
.cta {
font-family: var(--font-family-inter);
font-style: var(--font-style-normal);
font-weight: var(--font-weight-600);
font-size: var(--font-size-14);
line-height: var(--line-spacing-24);
letter-spacing: var(--character-spacing-0);
color: var(--color-506ff3);
}