@import "../../ThemeVariables.css";

.mobile-content{
    display: none;
}

@media all and (min-width: 320px) and (max-width: 990px) {
    .mobile-content {
        display: block;
        padding: 3rem 0rem;
    }

    .mobile-content>* {
        margin-bottom: 2rem;
    }

    .mobile-content>div:nth-child(1){
        display: grid;
        grid-template-columns: .1fr .75fr;
        align-items: center;
        justify-content: center;
        color: #191F23;
    }
    .mobile-content>div:nth-child(1) *{
    }

    .mobile-content>div:nth-child(1)>img {
        width: 40%;
    }

    .mobile-content>div:nth-child(1)>p {
        width: 100%;
        font-size: var(--font-size-24);
        font-weight: var(--font-weight-bold);
    }

    .mobile-content>div:nth-child(1) * {}


    .mobile-content>div:nth-child(2) {
        width: 100%;
        display: grid;
        grid-template-columns: .1fr .75fr;
        align-items: center;
        justify-content: center;
    }

    .mobile-content>div:nth-child(2)>p {
        font-size: var(--font-size-18);
        color: #75797B;
    }
}