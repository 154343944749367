@import "../../ThemeVariables.css";
.special-image{
    width: 50%;
}

@media all and (min-width: 320px) and (max-width: 990px){
    .special-image{
        width: 100%;
        padding: 0 5%;
    }
}