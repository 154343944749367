@import "../ThemeVariables.css";

/* MAIN FEATURES  */
.main-features-container {
    font-family: var(--font-family-inter);
}

.main-features-container>.feature-container-right {
    width: 70%;
    margin: auto;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 2.5rem 0;
    gap: 20%;
}

.main-features-container>.feature-container-right>.content-container {
    width: 45%;
}

.main-features-container>.feature-container-right>.content-container>img {
    width: 16%;
    margin-bottom:1rem;
}

.main-features-container>.feature-container-right>.content-container>div {
    width: 60%;
    display: flex;
    margin-bottom: 12%;
    position: relative;
}

.main-features-container>.feature-container-right>.content-container>div>p {
    font-size: 1.3rem;
    font-weight: 700;
    color: rgb(26, 26, 26);
}

.main-features-container>.feature-container-right>.content-container>p {
    width: 80%;
    font-size: 85%;
    font-weight: 550;
    color: rgb(100, 100, 100);
}

.main-features-container>.feature-container-right>.image-container {
    width: 55%;
}

/* left  */
.main-features-container>.feature-container-left {
    width: 70%;
    margin: auto;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 2.5rem 0;
    gap: 20%;
}

.main-features-container>.feature-container-left>.content-container {
    width: 45%;
}

.main-features-container>.feature-container-left>.content-container>img {
    width: 16%;
    margin-bottom:1rem;
}

.main-features-container>.feature-container-left>.content-container>div {
    width: 60%;
    display: flex;
    margin-bottom: 12%;
    position: relative;
}

.main-features-container>.feature-container-left>.content-container>div>p {
    font-size: 1.3rem;
    font-weight: 700;
    color: rgb(26, 26, 26);
}

.main-features-container>.feature-container-left>.content-container>p {
    width: 80%;
    font-size: 85%;
    font-weight: 550;
    color: rgb(100, 100, 100);
}

.main-features-container>.feature-container-left>.image-container {
    width: 55%;
}

/* common styles  */

.content-container>div>img {
    position: absolute;
    top: 10%;
    left: -10%;
    z-index: 3;
}

/* ALL FEATURES  */
.otherfeatures-container {
    background: url("../../images/b2c-commerce/texture.png");
    background-size: cover;
    text-align: center;
    opacity: 0.9;
    width: 80%;
    margin: auto;
    padding: 3rem 4rem;
}

.otherfeatures-container>.mainheading-container {
    font-size: 1.3rem;
    font-weight: 650;
}

.otherfeatures-container>.allfeatures {
    display: flex;
    justify-content: space-between;
    gap:2rem;
    margin-top: 3rem;
    font-family: var(--font-family-inter);
    opacity: 1;
}

.f-sec-cont{
    display: flex;
    width: 33%;
}

.f-sec-cont-left{
    width: 30%;
    margin-top:26%;
    margin-right: 10px;
}
.f-sec-cont-right{
    text-align: left;
}

.f-sec-cont-right-img{
    margin-bottom: 22px;
}
.f-sec-cont-right-title{
    margin-bottom: 6px;
   font-size:20px;
   font-weight:bold;
}
.f-sec-cont-right-subTitle{
   margin-bottom: 30px;
}

@media all and (max-width:990px){
    .main-features-container{
        padding: 0;
        width: 100%;
    }
    .main-features-container>.feature-container-left{
        flex-direction: column;
        margin: auto;
        width: 100%;
    }
    .main-features-container>.feature-container-left>.content-container{
        margin: auto;
        width: 75%;
    }
    .main-features-container>.feature-container-left>.content-container>div{
        margin-bottom: 1rem;
        width: 100%;
    }
    .main-features-container>.feature-container-left>.content-container>div>img{
        width: 7%;
    }
    .main-features-container>.feature-container-left>.image-container{
        width: 90%;
        margin: auto;
    }

    .main-features-container>.feature-container-right{
        flex-direction: column-reverse;
        margin: auto;
        width: 100%;
    }
    .main-features-container>.feature-container-right>.content-container{
        margin: auto;
        width: 75%;
    }
    .main-features-container>.feature-container-right>.content-container>div{
        margin-bottom: 1rem;
        width: 100%;
    }
    .main-features-container>.feature-container-right>.image-container{
        width: 90%;
        margin: auto;
    }

    /* other features  */
    .otherfeatures-container{
        width: 90%;
        padding: 4rem 1rem;
    }
    .otherfeatures-container>.mainheading-container{
        font-size: 1.5rem;
    }
    .otherfeatures-container>.allfeatures{
        flex-direction: column;
    }
    .otherfeatures-container>.allfeatures>.feature{
        width: 80%;
        margin: auto;
    }
    .otherfeatures-container>.allfeatures>.feature>div{
        font-size: var(--font-size-18);
    }
    .otherfeatures-container>.allfeatures>.feature>p{
        font-size: var(--font-size-14);
    }
    .otherfeatures-container>.allfeatures>.feature>div>img{
        width: 5%;
        top: 20%;
    }
    .otherfeatures-container>.allfeatures>.feature *{
        margin: .2rem 0;
    }

    .f-sec-cont{
        display: flex;
        width: 100%;
    }
    .f-sec-cont-left{
        width: 15%;
    }
     
}