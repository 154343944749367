@import "../../ThemeVariables.css";

.content-container {
    padding: 0 5%;
}

.content-container>* {
    margin-bottom: 3rem;
}

.content-container>p:nth-child(1) {
    font-size: var(--font-size-38);
    font-weight: var(--font-weight-bold);
}

.content-container>p:nth-child(2) {
    font-size: var(--font-size-18);
    font-weight: var(--font-weight-normal);
}

.content-container>p:nth-child(3) {
    display: flex;
    align-items: center;
    gap: 1rem;
    color: #506ff3;
    font-weight: var(--font-weight-600);
}

@media all and (min-width: 320px) and (max-width: 990px){
    .content-container {
        padding: 10% 10%;
    }
    
    .content-container>* {
        margin-bottom: 2rem;
    }
    
    .content-container>p:nth-child(1) {
        font-size: var(--font-size-28);
        font-weight: var(--font-weight-bold);
        color: #191F23;
    }
    
    .content-container>p:nth-child(2) {
        font-size: var(--font-size-18);
        font-weight: var(--font-weight-normal);
        color:#75797B;;
    }

    .content-container>p:nth-child(3){
        display: none;
    }
    
}