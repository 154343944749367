.benefits-section-container{
    display: flex;
    padding: 10%;
    gap: 2rem;
}
.benefits-section-container>.title-section{
    width: 30%;
    color: var(--color-191f23);
}
.benefits-section-container>.title-section>p:nth-child(1){
    font-size: 24px;
    font-weight: var(--font-weight-bold);
    margin-bottom: 2rem;
    padding: 1rem 0;
}
.benefits-section-container>.title-section>p:nth-child(2){
    font-size: 16px;
    color: var(--color-75797b);
}
.benefits-section-container>.benefits-list{
    width: 60%;
    display: grid;
    grid-template-columns: repeat(2,1fr);
    gap: 1rem;
}

.benefits-section-container>.benefits-list>p{
    font-size: 18px;
    color: var(--color-52636f);
    font-weight: var(--font-weight-medium);
}

@media all and (min-width: 320px) and (max-width: 990px){
    .benefits-section-container{
        display: block;
        padding: 0% 10% 10% 10%;
        gap: 2rem;
    }
    .benefits-section-container>.title-section{
        width: 100%;
        color: var(--color-191f23);
    }
    .benefits-section-container>.title-section>p:nth-child(1){
        font-size: 24px;
        font-weight: var(--font-weight-bold);
        margin-bottom: 1rem;
        padding: 1rem 0;
    }
    .benefits-section-container>.title-section>p:nth-child(2){
        font-size: 16px;
        color: var(--color-75797b);
    }
    .benefits-section-container>.benefits-list{
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        margin-top: 2rem;
    }
    
    .benefits-section-container>.benefits-list>p{
        font-size: 18px;
        color: var(--color-52636f);
        font-weight: var(--font-weight-medium);
    }
}