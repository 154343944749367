@import "../../ThemeVariables.css";


.hero-title{
    width: fit-content;
    padding: 5px 25px 5px 20px;
    border-radius: 15px;
    font-size: var(--font-size-14);
    font-weight: var(--font-weight-600);
    color: #30393f;
    background-color: rgb(243, 243, 243);
    cursor: pointer;
}

@media all and (min-width: 320px) and (max-width:990px){
    .hero-title{
        font-size: var(--font-size-12);
    }
}