@import "../../ThemeVariables.css";

.feature-section-container{
    width: 80%;
    margin: auto;
    color: black;
    /* padding: 10% 0; */
}

.feature-section-container>*{
    margin: 10% 0; 
}

.feature-section-container>.feature-left-section{
    display: flex;
    justify-content: center;
    align-items: center;
}

.feature-section-container>.feature-right-section{
    display: flex;
    justify-content: center;
    align-items: center;
}

@media all and (min-width: 320px) and (max-width: 990px){
    .feature-section-container{
        width: 100%;
        margin: auto;
        color: black;
        /* padding: 10% 0; */
    }
    
    .feature-section-container>*{
        margin: 10% 0; 
    }
    
    .feature-section-container>.feature-left-section{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    
    .feature-section-container>.feature-right-section{
        display: flex;
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
    }
}