@import "../../ThemeVariables.css";

.hero-section-container{
    font-family: var(--font-family-inter);
    width: 75%;
    margin: auto;
    padding: 7% 0;
    display: flex;
    margin-top: 12.5vh;
}

.hero-section-container>.left-section{
    width:50%;
}
.hero-section-container>.left-section>*{
    margin-bottom: 1rem;
}
.hero-section-container>.right-section{
    width:50%;
}

@media all and (min-width:320px) and (max-width:990px){
    .hero-section-container{
        font-family: var(--font-family-inter);
        width: 100%;
        margin: auto;
        display: flex;
        flex-direction: column-reverse;
    }
    
    .hero-section-container>.left-section{
        width:80%;
        margin: auto;
    }
    .hero-section-container>.left-section>*{
        margin-bottom: 1rem;
    }
    .hero-section-container>.right-section{
        width:100%;

    }
    
}