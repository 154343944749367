@import "../ThemeVariables.css";

#stories-container {
    background-color: #f8f8f8;
    padding: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: var(--font-family-inter);
    gap: 1.5rem;
    position: relative;
}

#stories-container>.section-name {
    font-size: var(--font-size-14);
    letter-spacing: 1.6px;
    color: #ED37A0;
    text-transform: uppercase;
    opacity: 1;
    font-weight: 700;
}

#stories-container>.section-heading {
    width: 50%;
    color: #191f23;
    text-align: center;
    letter-spacing: 0px;
    opacity: 1;
    font-size: var(--font-size-24);
    font-weight: 700;
    margin-bottom: 1.5rem;
}

#stories-container>.left-story-section {
    display: flex;
    align-items: center;
    text-align: left;
    background-color: white;
    padding: 3%;
    border: 1px solid rgba(181, 181, 181, .5);
    border-radius: 3px;
    gap: 4rem;
}

#stories-container>.left-story-section>img {
    width: 30%;
}

#stories-container>.left-story-section>.text-section {
    position: relative;
}

#stories-container>.left-story-section>.text-section>.content-title {
    font: var(--font-style-normal) normal medium var(--font-size-14)/var(--line-spacing-30) var(--font-family-inter);
    letter-spacing: var(--character-spacing-0);
    color: var(--color-3f60f5);
    font-weight: 550;
    margin-left: 1.5rem;
    margin-bottom: 1rem;
}

#stories-container>.left-story-section>.text-section>.more-details {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: .5rem;
}

#stories-container>.left-story-section>.text-section>.more-details>.story-description {
    font: var(--font-style-normal) normal var(--font-weight-600) 20px/var(--line-spacing-30) var(--font-family-inter);
    letter-spacing: var(--character-spacing-0);
    color: var(--color-191f23);
    font-weight: 500;
    margin-bottom: 1rem;
    text-align: justify;
    text-justify: distribute;
    text-align-last: left;
}

#stories-container>.left-story-section>.text-section>.story-details {
    font: var(--font-style-normal) normal var(--font-weight-normal) var(--font-size-18)/var(--line-spacing-30) var(--font-family-inter);
    letter-spacing: var(--character-spacing-0);
    color: var(--color-75797b);
    margin-left: 1.5rem;
    margin-bottom: 1rem;
}

#stories-container>.left-story-section>.text-section>.more-details>img {
    /* position: absolute;
    top: 24%;
    left: -3%; */

}


/* right section  */

#stories-container>.right-story-section {
    display: flex;
    align-items: center;
    text-align: left;
    background-color: white;
    padding: 5%;
    border: 1px solid rgba(181, 181, 181, .5);
    border-radius: 3px;
    gap: 4rem;
}

#stories-container>.right-story-section>img {
    width: 30%;
}

#stories-container>.right-story-section>.text-section {
    position: relative;
}

#stories-container>.right-story-section>.text-section>.content-title {
    font: var(--font-style-normal) normal medium var(--font-size-14)/var(--line-spacing-30) var(--font-family-inter);
    letter-spacing: var(--character-spacing-0);
    color: var(--color-3f60f5);
    font-weight: 550;
    margin-left: 1.5rem;
    margin-bottom: 1rem;
}

#stories-container>.right-story-section>.text-section>.more-details {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: .5rem;
}

#stories-container>.right-story-section>.text-section>.more-details>.story-description {
    font: var(--font-style-normal) normal var(--font-weight-600) 20px/var(--line-spacing-30) var(--font-family-inter);
    letter-spacing: var(--character-spacing-0);
    color: var(--color-191f23);
    font-weight: 500;
    margin-bottom: 1rem;
    text-align: justify;
    text-justify: distribute;
    text-align-last: left;
}

#stories-container>.right-story-section>.text-section>.story-details {
    font: var(--font-style-normal) normal var(--font-weight-normal) var(--font-size-18)/var(--line-spacing-30) var(--font-family-inter);
    letter-spacing: var(--character-spacing-0);
    color: var(--color-75797b);
    margin-left: 1.5rem;
}

#stories-container>.right-story-section>.text-section>.more-details>img {
    /* position: absolute;
    top: 26%;
    left: -3%; */
}

.hexagonStyle {
    position: absolute;
    top: 0;
    transform: translate(-250%, -50%);
}

.hexagonFill {
    position: absolute;
    top: 0;
    transform: translate(500%, 100%);
}

@media all and (max-width:812px) {
    #stories-container {
        margin: 0;
        padding: .7rem;
        padding-top: 2rem;
    }

    #stories-container>.section-name {
        color: #59C697;
        font-size: var(--font-size-14);
        font-weight: 600;
    }

    #stories-container>.section-heading {
        font-size: var(--font-size-18);
        font-weight: 700;
        width: 90%;
    }

    #stories-container>.left-story-section {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-right: 0;
        padding-top: 1rem;
        height: 50%;
    }

    #stories-container>.left-story-section>.text-section {
        padding: 1rem;
        width: 100%;
        /* border: 1px solid red; */
    }

    #stories-container>.left-story-section>.text-section>.content-title {
        font-size: 1.2rem;
        margin-bottom: 1rem;
    }

    #stories-container>.left-story-section>.text-section>.more-details {
        font-size: var(--font-size-16);
        font-weight: 600;
        margin-top: 1rem;
    }

    #stories-container>.left-story-section>.text-section>.story-details {
        font-size: var(--font-size-14);
        line-height: normal;
        margin-top: 1rem;
    }

    #stories-container>.left-story-section>.text-section>.more-details>.story-description {
        font-size: 16px;
    }

    #stories-container>.right-story-section>.text-section>.more-details>.story-description {
        font-size: 16px;
    }

    #stories-container>.left-story-section>img {
        width: 80%;
    }

    #stories-container>.right-story-section>img {
        width: 80%;
    }

    #stories-container>.right-story-section {
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        /* border: 1px solid red; */
        padding: 0;
        padding-top: 1rem;
        height: 50%;
    }

    #stories-container>.right-story-section>.text-section {
        padding: 1rem;
        width: 100%;
    }

    #stories-container>.right-story-section>.text-section>.content-title {
        font-size: 1.2rem;
    }

    #stories-container>.right-story-section>.text-section>.more-details {
        font-size: var(--font-size-16);
        font-weight: 600;
        margin-top: 1rem;
    }

    #stories-container>.right-story-section>.text-section>.more-details>img {
        /* position: absolute;
        top: 5%;
        left: -5%;
        width: 5%; */
    }

    #stories-container>.right-story-section>.text-section>.story-details {
        font-size: var(--font-size-14);
        line-height: normal;
        margin-top: 1rem;
    }

    .hexagonStyle,
    .hexagonFill {
        display: none;
    }
}