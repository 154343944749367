@import "../ThemeVariables.css";

.bio-container {
    background: rgb(80, 111, 240);
    background: linear-gradient(180deg, rgba(80, 111, 240, 1) 35%, rgba(99, 48, 196, 1) 92%);
    padding: 10%;
    margin-top: 12.5vh;
}

.bio-container>button {
    display: flex;
    background-color: white;
    align-items: center;
    justify-content: space-between;
    border-radius: 15px;
    padding: 2px 17px;
    gap: 10px;
}

.bio-container>div>img {
    width: 20%;
    margin: 0 0 5% 0;
}

.bio-container>div>div>div>p:first-child {
    font-size: var(--font-size-24);
    color: white;
    font-weight: var(--font-weight-medium);
}

.bio-container>div>* {
    margin-bottom: 3rem;
}

.bio-container>div>div>p:nth-child(2) {
    font-size: var(--font-size-14);
    color: white;
    font-weight: var(--font-weight-600);
}

.bio-container>div>div>div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 25%;
}

.bio-container>div>div>a>button {
    display: flex;
    align-items: center;
    gap: 10px;
    background-color: var(--color-506ff3);
    padding: 5px 15px;
    border-radius: 20px;
    color: white;
    font-weight: var(--font-weight-medium);
    margin: 15px 0 30px 0;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
}

.bio-container>div>p {
    color: white;
    font-size: 18px;
    text-align: justify;
}

@media all and (min-width:320px) and (max-width:990px) {
    .bio-container {
        background: rgb(80, 111, 240);
        background: linear-gradient(180deg, rgba(80, 111, 240, 1) 35%, rgba(99, 48, 196, 1) 92%);
        padding: 10%;
    }

    .bio-container>button {
        display: flex;
        background-color: white;
        align-items: center;
        justify-content: space-around;
        border-radius: 15px;
        padding: 2px 17px;
        gap: 10px;
    }

    .bio-container>div>img {
        width: 70%;
        margin: 5% 0;
    }

    .bio-container>div>div>div{
        width: 90%;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .bio-container>div>div>div>p:first-child {
        font-size: var(--font-size-18);
        color: white;
        font-weight: var(--font-weight-medium);
    }

    .bio-container>div>* {
        margin-bottom: 3rem;
    }

    .bio-container>div>div>p:nth-child(2) {
        font-size: var(--font-size-14);
        color: white;
        font-weight: var(--font-weight-600);
    }

    .bio-container>div>div>a>button {
        display: flex;
        align-items: center;
        gap: 10px;
        background-color: var(--color-506ff3);
        padding: 5px 15px;
        border-radius: 20px;
        color: white;
        font-weight: var(--font-weight-medium);
        margin: 15px 0 30px 0;
    }

    .bio-container>div>p {
        color: white;
        font-size: 18px;
    }
}