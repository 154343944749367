@import "../ThemeVariables.css";


.TS-Title{
    font-family: var(--font-family-inter);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--unnamed-color-191f23);
    text-align: left;
    font: normal normal bold 38px/54px Inter;
    letter-spacing: 0px;
    color: #191F23;
    opacity: 1;
    text-align: center;
    background-color: #e6ebed;
    padding-top:4%;
    padding-bottom:4%;
    margin-top: 12.5vh;
}

.TS-container{
    font-family: var(--font-family-inter);
    display: flex;
}

.TS-left-container{
    width: 29%;
    text-align:left;
    padding-left:145px;
    padding-top:9%;
    border-right: 2px solid rgb(211, 205, 205);
}
.TS-right-container{
    width: 70%;
    padding-bottom: 6%;

}

.TS-left-head{
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) 13px/16px var(--unnamed-font-family-inter);
    color: var(--unnamed-color-a1a1aa);
    text-align: left;
    font: normal normal bold 13px/16px Inter;
    letter-spacing: 2.6px;
    color: #A1A1AA;
    text-transform: uppercase;
    opacity: 1;
    padding-bottom: 15px;
}

.TS-left-Link{
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-600) 16px/var(--unnamed-line-spacing-30) var(--unnamed-font-family-inter);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--unnamed-color-506ff3);
    text-align: left;
    font: normal normal 600 16px/30px Inter;
    letter-spacing: 0px;
    color: #506FF3;
    opacity: 1;
    border-left: 3px solid #506FF3;
    margin-bottom: 13px;
    padding-left:7px ;
    cursor: pointer;
}

.TS-r-head{
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) 13px/16px var(--unnamed-font-family-inter);
    color: var(--unnamed-color-59c697);
    text-align: left;
    font: normal normal bold 13px/16px Inter;
    letter-spacing: 2.6px;
    color: #59C697;
    text-transform: uppercase;
    opacity: 1;
    padding-bottom:9%;
}
.TS-r-subhead-box{
    border-bottom: 2px solid rgb(214, 209, 209);
    padding-bottom: 9%;
}

.TS-r-subhead{
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--unnamed-color-75797b);
    text-align: left;
    letter-spacing: 0px;
    color: #75797B;
    opacity: 1;
    padding-bottom:4%;
}

.TS-right-container{
    font-family: var(--font-family-inter);
    padding-left:45px; 
    padding-top:9%; 
    padding-right: 10%;
}

.TS-r-info-question{
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) var(--unnamed-font-size-24)/var(--unnamed-line-spacing-29) var(--unnamed-font-family-inter);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--unnamed-color-191f23);
    text-align: left;
    font: normal normal bold 24px/29px Inter;
    letter-spacing: 0px;
    color: #191F23;
    opacity: 1;
    padding-bottom: 7%;
    padding-top:10%;
}

.TS-r-info-details{
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-18)/var(--unnamed-line-spacing-30) var(--unnamed-font-family-inter);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--unnamed-color-75797b);
    text-align: left;
    font: normal normal normal 18px/30px Inter;
    letter-spacing: 0px;
    color: #75797B;
    opacity: 1;
    padding-bottom: 3%;
}

.TS-r-info-readbox{
    display: flex;
    width: 17%;
    justify-content: space-between;
    color: #506FF3;
    align-items: center;
}

.TS-r-info-readText{
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-600) var(--unnamed-font-size-14)/var(--unnamed-line-spacing-24) var(--unnamed-font-family-inter);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--unnamed-color-506ff3);
    text-align: left;
    font: normal normal 600 14px/24px Inter;
    letter-spacing: 0px;
    color: #506FF3;
    opacity: 1;
}



@media screen and (min-width: 600px) {
    .mobile-link-box{
        display: none;
    }
}


@media screen and (max-width: 600px) {
    .TS-Title{
        padding-top:20%;
        padding-bottom:20%;
    }
    .TS-container{
        flex-direction: column;
    }

    .TS-left-container{
        width:100%;
        display: none;
    }
    .TS-right-container{
        width: 100%;
        padding-bottom: 6%;
    
    }
    .TS-right-container{
        font-family: var(--font-family-inter);
        padding-left:5%; 
        padding-top:9%; 
        padding-right:5%;
    }

    .TS-r-info-readbox{
        width: 30%;
    }
    .TS-left-Link-mobile{
        font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-600) 16px/var(--unnamed-line-spacing-30) var(--unnamed-font-family-inter);
        letter-spacing: var(--unnamed-character-spacing-0);
        color: var(--unnamed-color-506ff3);
        text-align: left;
        font: normal normal 600 16px/30px Inter;
        letter-spacing: 0px;
        color: #506FF3;
        opacity: 1;
        width: 95%;
        margin-bottom: 13px;
        padding-left:7px ;
        cursor: pointer; 
        background-color: #f1f4f8;
    }
    .TS-left-head{
        margin-left: 3%;
        padding-top: 5%;
    }
    .mobile-link-box{
       background-color: #f1f4f8;
    }
}

