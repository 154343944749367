.soon-cont{
    width: 100%;
    margin-top: 5%;
}

@media only screen and (max-width: 600px) and (min-width: 200px) {

    .soon-cont{
        width: 100%;
        margin-top:30%;
        margin-bottom: 5%;
    }

}