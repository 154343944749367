@import "../../ThemeVariables.css";

.feature-content-container{
    width: 45%;
    padding: 0 5%;
}
.feature-content-container>*{
    margin: 10% 0;
}
.feature-content-container>.heading{
    display: grid;
    grid-template-columns: .1fr .9fr;
    justify-content: center;
    gap: 0;
    font-size: var(--font-size-38);
    font-weight: var(--font-weight-bold);
    line-height: 3rem;
    width: 80%;
}
.feature-content-container>.description{
    display: grid;
    grid-template-columns: .1fr .9fr;
    justify-content: center;
    gap: 0;
    font-size: var(--font-size-18);
    color: var(--color-75797b);
    font-weight: 450;
}

.feature-content-container>.read-more{
    display: grid;
    grid-template-columns: .1fr .9fr;
    justify-content: center;
    gap: 0;
}

@media all and (min-width: 320px) and (max-width: 990px){
    .feature-content-container{
        width: 100%;
        padding: 10% 10%;
    }
    .feature-content-container>*{
        margin-bottom: 2rem;
    }
    .feature-content-container>.heading>img{
        display: none;
    }
    .feature-content-container>.heading{
        display: block;
        font-size: var(--font-size-28);
        font-weight: var(--font-weight-bold);
        line-height: 3rem;
        width: 100%;
    }
    .feature-content-container>.heading>p{
        width: 100%;
    }
    .feature-content-container>.description>div{
        display: none;
    }
    .feature-content-container>.description{
        display:block;
        font-size: var(--font-size-18);
        color: var(--color-75797b);
        font-weight: 450;
    }
    
    .feature-content-container>.read-more>div{
        display: none;
    }

    .feature-content-container>.read-more{
       display: block;
    }
}