@import "../ThemeVariables.css";


  
.CU-container{
    background: #DEF2E7 0% 0% no-repeat padding-box;
    border-radius: 4px;
    opacity: 1;
   font-family: var(--font-family-inter);
   padding-top: 8%;
   padding-bottom: 8%;
}

.CU-main-title{
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) var(--unnamed-font-size-38)/54px var(--unnamed-font-family-inter);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--unnamed-color-191f23);
    text-align: center;
    font: normal normal bold 38px/54px Inter;
    letter-spacing: 0px;
    color: #191F23;
    opacity: 1;
    font-family: var(--font-family-inter);
}

.CU-main-Subtitle{
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) 20px/var(--unnamed-line-spacing-30) var(--unnamed-font-family-inter);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--unnamed-color-75797b);
    text-align: center;
    font: normal normal normal 20px/30px Inter;
    letter-spacing: 0px;
    color: #75797B;
    opacity: 1;
    font-family: var(--font-family-inter);
    padding-left:16%;
    padding-right:16%;
    padding-top: 3%;
    padding-bottom: 7%;
}

.talk-container{
    width: 50%;
    margin: auto;
    background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 10px 15px #00000005;
    border-radius: 19px;
    opacity: 1;
    font-family: var(--font-family-inter);
    padding-bottom: 5%;
    
}

.talk-TalkHead{
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) 13px/16px var(--unnamed-font-family-inter);
    color: var(--unnamed-color-59c697);
    text-align: left;
    font: normal normal bold 13px/16px Inter;
    letter-spacing: 2.6px;
    color: #59C697;
    text-transform: uppercase;
    opacity: 1;
    text-align: center;
    padding-top:12%;
    /* padding-bottom: 4%; */
}

.talk-TalkSubHead{
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) var(--unnamed-font-size-24)/38px var(--unnamed-font-family-inter);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--unnamed-color-191f23);
    text-align: center;
    font: normal normal bold 24px/38px Inter;
    letter-spacing: 0px;
    color: #191F23;
    opacity: 1;
    padding-left: 19%;
    padding-right: 19%;
    padding-top:5%;
}

.input-cont{
    padding-top: 5%;
    text-align: center;
}

.TalkInput{
    width: 504px;
    height: 51px;
    background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
    border: 1px solid var(--unnamed-color-e6ebed);
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #E6EBED;
    border-radius: 2px;
    opacity: 1;
    margin-bottom: 3%;
    padding-left: 4%;
}

.textaria{
    width: 504px;
    height: 100px;
    background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
    border: 1px solid var(--unnamed-color-e6ebed);
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #E6EBED;
    border-radius: 2px;
    opacity: 1;
    margin-bottom: 3%;
    padding-top: 1.5%;
    padding-left: 4%;
}

.CU-term-box{
    display: flex;
    padding: 8%;
    justify-content: flex-start;
    align-items: flex-start;
}

.CU-term-input{
    margin-top:0.7%;
}



.CU-term-title{
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) 12px/var(--unnamed-line-spacing-24) var(--unnamed-font-family-inter);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--unnamed-color-75797b);
    text-align: left;
    font: normal normal normal 12px/24px Inter;
    letter-spacing: 0px;
    color: #75797B;
    opacity: 1;
}


.CU-btn-box{
    width:200px;
    height: 50px;
    background: #506FF3 0% 0% no-repeat padding-box;
    border-radius: 26px;
    opacity: 1;
    font-family: var(--font-family-inter);
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}


.CU-btn-text{
    font: var(--unnamed-font-style-normal) normal medium 16px/var(--unnamed-line-spacing-24) var(--unnamed-font-family-inter);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--unnamed-color-ffffff);
    text-align: left;
    font: normal normal medium 16px/24px Inter;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
}

.addresh-cont{
    background: var(--unnamed-color-f1f4f8) 0% 0% no-repeat padding-box;
    background: #F1F4F8 0% 0% no-repeat padding-box;
    border-radius: 4px;
    opacity: 1;
    padding-top: 7%;
    padding-left: 11%;
    padding-right: 11%;
    padding-bottom: 1%;
}

.office-box{
    display: grid;
    grid-template-columns: repeat(3,1fr);
    gap:3%;
}
.addresh-head{
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) var(--unnamed-font-size-38)/54px var(--unnamed-font-family-inter);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--unnamed-color-191f23);
    text-align: left;
    font: normal normal bold 38px/54px Inter;
    letter-spacing: 0px;
    color: #191F23;
    opacity: 1;
    padding-bottom: 7%;
}

.addresh-Inner-Box{
    background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    opacity: 1;
    font-family: var(--font-family-inter);
    padding:7%;
}

.addresh-Inner-text{
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) 13px/16px var(--unnamed-font-family-inter);
    color: var(--unnamed-color-191f23);
    text-align: left;
    font: normal normal bold 13px/16px Inter;
    letter-spacing: 2.6px;
    color: #191F23;
    text-transform: uppercase;
    opacity: 1;
    margin-bottom: 5%;
}
.addresh-Inner-Img{
    width: 53px;
    height: 27px;
    margin-bottom: 10%;
}
.addresh-Inner-addresh{
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-14)/var(--unnamed-line-spacing-26) var(--unnamed-font-family-inter);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--unnamed-color-75797b);
    text-align: left;
    font: normal normal normal 14px/26px Inter;
    letter-spacing: 0px;
    color: #75797B;
    opacity: 1;
    margin-bottom: 6%;
}

.addresh-Inner-contact{
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-14)/var(--unnamed-line-spacing-26) var(--unnamed-font-family-inter);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--unnamed-color-75797b);
    text-align: left;
    font: normal normal normal 14px/26px Inter;
    letter-spacing: 0px;
    color: #75797B;
    opacity: 1;
    margin-bottom: 5%;
}

.view-btn-cont{
    display: flex;
}

.view-btn-text{
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-600) var(--unnamed-font-size-14)/var(--unnamed-line-spacing-24) var(--unnamed-font-family-inter);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--unnamed-color-506ff3);
    text-align: left;
    font: normal normal 600 14px/24px Inter;
    letter-spacing: 0px;
    color: #506FF3;
    opacity: 1;
}



@media screen and (max-width: 600px) {
   
       
.CU-container{
   text-align: center;
   padding-left:3% ;
   padding-right:3% ;
   margin-top: 10%;
}

.CU-main-title{
    padding-top: 8%;
    padding-bottom: 8%;
}

.CU-main-Subtitle{
    padding-left:6%;
    padding-right:6%;
    padding-top: 3%;
    padding-bottom: 12%;
}

.talk-container{
    width: 100%;
    margin: auto;   
}


.talk-TalkSubHead{
    padding-left: 3%;
    padding-right: 3%;
    padding-top:5%;
}

.input-cont{
    padding-top: 5%;
    text-align: center;
}

.TalkInput{
    width: 85%;
    height: 45px;
}
.textaria{
    width: 85%;
}
.CU-term-input{
    margin-top:1%;
}








.addresh-cont{
    padding-top: 7%;
    padding-left: 11%;
    padding-right: 11%;
    padding-bottom: 44%;
}

.office-box{
    grid-template-columns: repeat(1,1fr);
}
.addresh-head{
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) var(--unnamed-font-size-38)/54px var(--unnamed-font-family-inter);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--unnamed-color-191f23);
    text-align: left;
    font: normal normal bold 38px/54px Inter;
    letter-spacing: 0px;
    color: #191F23;
    opacity: 1;
    padding-bottom: 7%;
}

.addresh-Inner-Box{
    background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    opacity: 1;
    font-family: var(--font-family-inter);
    padding:7%;
}

.addresh-Inner-text{
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) 13px/16px var(--unnamed-font-family-inter);
    color: var(--unnamed-color-191f23);
    text-align: left;
    font: normal normal bold 13px/16px Inter;
    letter-spacing: 2.6px;
    color: #191F23;
    text-transform: uppercase;
    opacity: 1;
    margin-bottom: 20%;
}
.addresh-Inner-Img{
    width: 53px;
    height: 27px;
    margin-bottom: 20%;
}
.addresh-Inner-addresh{
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-14)/var(--unnamed-line-spacing-26) var(--unnamed-font-family-inter);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--unnamed-color-75797b);
    text-align: left;
    font: normal normal normal 14px/26px Inter;
    letter-spacing: 0px;
    color: #75797B;
    opacity: 1;
    margin-bottom: 6%;
}

.addresh-Inner-contact{
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-14)/var(--unnamed-line-spacing-26) var(--unnamed-font-family-inter);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--unnamed-color-75797b);
    text-align: left;
    font: normal normal normal 14px/26px Inter;
    letter-spacing: 0px;
    color: #75797B;
    opacity: 1;
    margin-bottom: 5%;
}

.view-btn-cont{
    display: flex;
}

.view-btn-text{
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-600) var(--unnamed-font-size-14)/var(--unnamed-line-spacing-24) var(--unnamed-font-family-inter);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--unnamed-color-506ff3);
    text-align: left;
    font: normal normal 600 14px/24px Inter;
    letter-spacing: 0px;
    color: #506FF3;
    opacity: 1;
}

}