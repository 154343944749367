.hsection-img{
    width: 100%;
}
.gallery-img{
    width: 50%;
}
@media all and (min-width:320px) and (max-width:990px){
    .hsection-img{
        width: 100%;
    }
    .gallery-img{
        width: 100%;
    }
}