@import "../ThemeVariables.css";

.aboutus-container * {
    font-family: var(--font-family-inter);
}

/* Hero Section  */
.aboutus-container>.hero-section {
    /* height: 100vh; */
    background-size: cover;
    padding: 5% 0 0% 0;
    margin-top: 12.5vh;
}

.aboutus-container>.hero-section>.text-content {
    width: 80%;
    margin: auto;
    text-align: center;
    
}

.aboutus-container>.hero-section>.text-content>p:first-child {
    color: white;
    font-size: var(--font-size-13);
    font-weight: var(--font-weight-medium);
    letter-spacing: 2.6px;
    background-color: rgba(255,255,255,0.22);
    width: fit-content;
    padding: .5% 1.2%;
    margin: 0px auto 20px auto;
    border-radius: 14px;
}

.aboutus-container>.hero-section>.text-content>p:nth-child(2) {
    color: var(--color-ffffff);
    font-size: var(--font-size-38);
    font-weight: var(--font-weight-600);
    letter-spacing: var(--letter-spacing-0);
    padding-bottom: 10px;
}

.aboutus-container>.hero-section>.text-content>p:nth-child(3){
    color: #FEFEFE;
    line-height: 30px;
    opacity: .69;
    margin-bottom: 2rem;
}

/* Values Section*/
.aboutus-container>.values-section {
    width: 80%;
    margin: auto;
    margin-bottom: 10%;
}

.aboutus-container>.values-section>.title-content>p:first-child {
    font-size: var(--font-size-13);
    font-weight: var(--font-weight-bold);
    margin: 10% 0 2.5% 0;
    letter-spacing: 2.6px;
}

.aboutus-container>.values-section>.title-content>p:last-child {
    width: 35%;
    font-size: var(--font-size-38);
    font-weight: var(--font-weight-medium);
    color: var(--color-191f23);
    margin: 2.5% 0 10% 0;
}

.aboutus-container>.values-section>.cards-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 4%;
}

.aboutus-container>.values-section>.cards-container>.card {
    width: 90%;
    height: 60vh;
    padding: 30% 10%;
    font-size: var(--font-size-24);
    font-weight: var(--font-weight-600);
    color: var(--color-191f23);
    box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em;
    border-radius: 0px;
    transition: .5s;
}

.aboutus-container>.values-section>.cards-container>.card:hover {
    width: 90%;
    padding: 30% 10%;
    font-size: var(--font-size-24);
    color: var(--color-ffffff);
    background: linear-gradient(180deg, rgba(250, 90, 104, 1) 41%, rgba(196, 50, 110, 1) 90%);
    border-radius: 0px;
}

/* landscape section  */
.aboutus-container>.landscape-container {
    background-color: var(--color-f1f4f8);
    padding: 5% 0;
}

.aboutus-container>.landscape-container>.text-content>p:nth-child(1) {
    font-size: var(--font-size-38);
    font-weight: var(--font-weight-medium);
    color: var(--color-191f23);
    text-align: center;
}

.aboutus-container>.landscape-container>.text-content>p:nth-child(2) {
    font-size: var(--font-size-18);
    font-weight: var(--font-weight-medium);
    color: var(--color-191f23);
    text-align: center;
    margin-top: 2%;
}

.aboutus-container>.landscape-container>img {
    width: 50%;
    margin: auto;
    margin-top: 5%;
}

/* timeline section  */
.aboutus-container>.timeline-container {
    /* padding: 10%; */
    /* height: 100vh; */
    background: rgb(20, 4, 48);
    background: linear-gradient(0deg, rgba(20, 4, 48, 1) 41%, rgba(25, 31, 32, 1) 79%);
    height: 100px;
}

.aboutus-container>.timeline-container>.title-content {
    padding: 1% 0;
}

.aboutus-container>.timeline-container>.title-content>p {
    font-size: var(--font-size-13);
    font-weight: var(--font-weight-bold);
    letter-spacing: 2.6px;
    color: var(--color-fcc15f);
    text-align: center;
}

.aboutus-container>.timeline-container>.timeline>.time * {

    margin: 0;
    padding: 0;
}

.aboutus-container>.timeline-container>.timeline>.time {
    display: grid;
    grid-template-columns: .6fr .25fr 1fr;
    align-items: flex-start;

}

.aboutus-container>.timeline-container>.timeline>.time>p:first-child {
    color: var(--color-fcc15f);
    text-align: right;
    height: 15vh;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-size: var(--font-size-18);
    font-weight: var(--font-weight-bold);
}

.aboutus-container>.timeline-container>.timeline>.time>div>img {
    width: 50%;
    margin: auto;
    position: relative;
    top: 0;
}

.aboutus-container>.timeline-container>.timeline>.time>p:last-child {
    height: 15vh;
    width: 80%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: var(--color-ffffff);
    font-weight: var(--font-weight-medium);
    font-size: var(--font-size-24);
}

.aboutus-container>.timeline-container>.MobileTimeline {
    display: none;
}

/* leadersSection  */
.aboutus-container>.leadership-container>.cards-container {
    width: 80%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    margin: auto;
    gap: 10%;
}
.timeline-container .carousel-container .carousel-buttons p{
    display: none;
}


/* Responsive Design */

@media all and (min-width:320px) and (max-width:990px) {

    /* Hero Section  */
    .aboutus-container>.hero-section {
        height: auto;
        display: flex;
        align-items: center;
        background-size: cover;
        background-position: 25%;
        padding: 0 0 10% 0;
    }

    .aboutus-container>.hero-section>.text-content {
        width: 85%;
        margin: auto;
        text-align: center;
    }

    .aboutus-container>.hero-section>.text-content>p:first-child {
        color: white;
        font-size: var(--font-size-13);
        font-weight: var(--font-weight-bold);
        margin: 25% auto 10% auto;
        padding: 1% 3%;
    }

    .aboutus-container>.hero-section>.text-content>p:nth-child(2){
        font-size: 22px;
    }

    .aboutus-container>.hero-section>.text-content>p:last-child {
        color: var(--color-ffffff);
        font-size: var(--font-size-24);
        font-weight: var(--font-weight-normal);
        letter-spacing: var(--letter-spacing-0);
        line-height: 46px;
    }
    .aboutus-container>.landscape-container>img {
        width: 50%;
        margin: auto;
        margin-top: 5%;
    }
    /* Values Section*/
    .aboutus-container>.values-section {
        width: 80%;
        margin: auto;
        margin-bottom: 25%;
        height: min-content;
    }

    .aboutus-container>.values-section>.title-content>p:first-child {
        font-size: var(--font-size-13);
        font-weight: var(--font-weight-bold);
        margin: 10% 0 2.5% 0;
        letter-spacing: 2.6px;
    }

    .aboutus-container>.values-section>.title-content>p:last-child {
        width: 85%;
        font-size: var(--font-size-28);
        font-weight: var(--font-weight-medium);
        color: var(--color-191f23);
        margin: 2.5% 0 10% 0;
    }

    .aboutus-container>.values-section>.cards-container {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 4%;
    }

    .aboutus-container>.values-section>.cards-container>.card {
        width: 95%;
        height: 45vh;
        margin: auto;
        font-size: var(--font-size-24);
        font-weight: var(--font-weight-600);
        color: var(--color-191f23);
        box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em;
        border-radius: 0px;
        display: flex;
        align-items: center;
    }

    .aboutus-container>.values-section>.cards-container>.card:hover {
        width: 95%;
        font-size: var(--font-size-20);
        color: var(--color-ffffff);
        background: rgb(250, 90, 104);
        background: linear-gradient(180deg, rgba(250, 90, 104, 1) 41%, rgba(196, 50, 110, 1) 90%);
        border-radius: 0px;
        display: flex;
        align-items: center;
    }

    .aboutus-container>.values-section>.cards-container>.card * {
        /* width: 80%; */
    }

    /* Timeline section  */

    .aboutus-container>.timeline-container {
        padding: 10%;
        background: rgb(20, 4, 48);
        background: linear-gradient(0deg, rgba(20, 4, 48, 1) 41%, rgba(25, 31, 32, 1) 79%);
    }

    .aboutus-container>.timeline-container>.title-content {
        padding: 0 0 10% 0;
    }

    .aboutus-container>.timeline-container>.title-content>p {
        font-size: var(--font-size-13);
        font-weight: var(--font-weight-bold);
        letter-spacing: 2.6px;
        color: var(--color-fcc15f);
        text-align: center;
    }

    .aboutus-container>.timeline-container>.timeline>.time * {

        margin: 0;
        padding: 0;
    }

    .aboutus-container>.timeline-container>.timeline>.time {
        display: grid;
        grid-template-columns: .6fr .25fr 1fr;
        align-items: flex-start;

    }

    .aboutus-container>.timeline-container>.timeline>.time>p:first-child {
        color: var(--color-fcc15f);
        text-align: right;
        height: 15vh;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        font-size: var(--font-size-22);
        font-weight: var(--font-weight-bold);
    }

    .aboutus-container>.timeline-container>.timeline>.time>div>img {
        width: 50%;
        margin: auto;
        position: relative;
        top: 0;
    }

    .aboutus-container>.timeline-container>.timeline>.time>p:last-child {
        height: 15vh;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        color: var(--color-ffffff);
        font-weight: var(--font-weight-600);
        font-size: var(--font-size-22);
    }

    /* landscape section  */
    .aboutus-container>.landscape-container {
        background-color: var(--color-f1f4f8);
        padding: 10% 0;
    }

    .aboutus-container>.landscape-container>.text-content>p:nth-child(1) {
        font-size: var(--font-size-22);
        font-weight: var(--font-weight-medium);
        color: var(--color-191f23);
        text-align: center;
    }

    .aboutus-container>.landscape-container>.text-content>p:nth-child(2) {
        font-size: var(--font-size-13);
        font-weight: var(--font-weight-medium);
        color: var(--color-191f23);
        text-align: center;
        margin-top: 2%;
    }

    .aboutus-container>.landscape-container>img {
        width: 80%;
        margin: auto;
        margin-top: 5%;
    }

    /* timeline section  */
    .aboutus-container>.timeline-container {
        padding: 20% 10%;
        height: auto;
        background: rgb(20, 4, 48);
        background: linear-gradient(0deg, rgba(20, 4, 48, 1) 41%, rgba(25, 31, 32, 1) 79%);
    }

    .aboutus-container>.timeline-container>.title-content {
        margin-bottom: 10%;
    }

    .aboutus-container>.timeline-container>.timeline {
        display: none;
    }

    .aboutus-container>.timeline-container>.MobileTimeline {
        display: grid;
        grid-template-columns: .25fr .75fr;
    }

    .aboutus-container>.timeline-container>.MobileTimeline>.text-content {}

    .aboutus-container>.timeline-container>.MobileTimeline>.text-content>p:nth-child(1) {
        color: #fbAd2a;
        font-size: 14px;
        font-weight: 600;
    }

    .aboutus-container>.timeline-container>.MobileTimeline>.text-content>p:nth-child(2) {
        color: white;
        font-size: 18px;
        padding-top: 1rem;
    }

    .aboutus-container>.timeline-container>.MobileTimeline>img {
        width: 70%;
    }

    /* leadersSection  */
    .aboutus-container>.leadership-container {
        /* padding-bottom: 5%; */
    }

    .aboutus-container>.leadership-container>.cards-container {
        width: 80%;
        display: block;
        margin: auto;
        /* height: 300vh; */
    }
}