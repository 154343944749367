@import "../ThemeVariables.css";

#usecase-container {
    font-style: var(--font-family-inter);
    padding: 8% 0;
    position: relative;
}

#usecase-container>.title-section {
    width: 90%;
    font: var(--font-style-normal) normal var(--font-weight-bold) 13px/16px var(--font-family-inter);
    text-align: left;
    letter-spacing: 2px;
    color: #E500DC;
    text-transform: uppercase;
    font-size: var(--font-size-14);
    opacity: 1;
    margin: auto;
}

#usecase-container>.title-content{
    width: 90%;
    display: flex;
    align-items: flex-start;
    text-align: left;
    font-size: var(--font-size-38);
    gap: .3rem;
    margin: auto;
    margin-top: 1rem;
    position: relative;
}

#usecase-container>.title-content>img {
    width: 1.2%;
    left: -25px;
    position: absolute;

}

#usecase-container>.title-content>p {
    font: var(--font-size-22);
    width: 40%;
    letter-spacing: var(--character-spacing-0);
    color: var(--color-191f23);
    font-weight: bold;
}

#usecase-container>.first-block {
    display: flex;
    width: 80%;
    margin: auto;
    margin-top: 70px;
    gap: 5%;
    /* position: absolute;
    bottom: -50%; */
}

#usecase-container>.first-block>.usecase-card {
    width: 33.33%;
    text-align: center; 
    padding: 2rem 0;
    color: #75797b;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.5rem;
    font-weight: 500;
    background-color: white;
    border-radius: 9px;
    font-size: 18px;
    /* box-shadow: rgba(88, 88, 88, 0.02) 0px 1px 3px 0px, rgba(53, 53, 53, 0.15) 0px 0px 0px 1px; */
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    padding: 3rem 0;
}

#usecase-container>.first-block>.usecase-card>p{
    width: 80%;
}

#usecase-container>.first-block>.usecase-card>button {
    border: none;
    font-size: 18px;
    padding: .6rem 1rem;
    color: #506FF3;
    background-color: #EDF0FE;
    font-weight: 600;
    border-radius: 26px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 7px;

}

#usecase-container>.first-block>.usecase-card>button:hover {
    background-color: #506FF3;
    color: #EDF0FE;
}

#usecase-container>.second-block {
    /* display: flex; */
    display: grid;
    grid-template-columns: 40% 40%;
    justify-content: center;
    gap: 5%;
    width: 60%;
    margin: auto;
    margin-top: 33px;
}

#usecase-container>.second-block>.usecase-card {
    /* width: 35%; */
    text-align: center;
    margin: auto;
    padding: 2.5rem 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.5rem;
    color: #75797b;
    font-size: 14px;
    height: 100%;
    font-weight: 500;
    border-radius: 9px;
    font-size: 18px;
    /* box-shadow: rgba(88, 88, 88, 0.02) 0px 1px 3px 0px, rgba(53, 53, 53, 0.15) 0px 0px 0px 1px; */
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

#usecase-container>.second-block>.usecase-card>p{
    width: 100%;
}

#usecase-container>.second-block>.usecase-card>button {
    border: none;
    font-size: 18px;
    padding: .6rem;
    color: #506FF3;
    background-color: #EDF0FE;
    font-weight: 600;
    border-radius: 26px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 7px;
    padding: .6rem 1rem;
    font-weight: 600;
}

#usecase-container>.second-block>.usecase-card>button:hover {
    background-color: #506FF3;
    color: #EDF0FE;
}

@media all and (max-width:812px){
    #usecase-container{
        background: white !important;
    }
    #usecase-container>.title-section{
        width: 85%;
        font-size: 1.1rem;
        margin: auto;
    }
    #usecase-container>.title-content{
        font-size: var(--font-size-16);
        width: 90%;
        padding: 0 .7rem;
        text-align: left;
        margin: auto;
        margin-top: 1rem;
        text-align: left;
    }
    #usecase-container>.title-content>img {
        width: 3.8%;
        margin-top: .7rem;
        display: none;
    }
    #usecase-container>.title-content>p{
        font-size: 1.5rem;
        margin: 0;
        width: 80%;

    }
    #usecase-container>.first-block{
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: center;
        justify-content: center;
        gap: 1rem;
    }
    #usecase-container>.first-block>.usecase-card{
        width: 80%;
        padding: 2rem;
    }
    #usecase-container>.first-block>.usecase-card>p{
        width: 80%;
        font-size: var(--font-size-16);
        margin-bottom: 1rem;
        margin-top: 1rem;
    }
    #usecase-container>.first-block>.usecase-card>button{
        display: flex;
        justify-content: space-around;
        align-items: center;
        padding: 5%;
        font-size: var(--font-size-14);
        width: 90%;
        font-weight: 550;
        gap: 3px;
    }
    #usecase-container>.second-block{
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: center;
        justify-content: center;
        gap: 1rem;
    }
    #usecase-container>.second-block>.usecase-card{
        width: 80%;
        /* padding: 2rem; */
        height: auto;
    }
    #usecase-container>.second-block>.usecase-card>p{
        width: 90%;
        font-size: var(--font-size-16);
        margin-bottom: 1rem;
        margin-top: 1rem;
    }
    #usecase-container>.second-block>.usecase-card>button{
        display: flex;
        justify-content: space-around;
        align-items: center;
        padding: 5%;
        font-size: var(--font-size-14);
    }
}