@import "../../ThemeVariables.css";

.hero-button{
    border-radius: 30px;
    padding: 15px 25px;
    color: var(--color-52636f);
    display: flex;
    gap: 1rem;
    align-items: center;
    font-weight: var(--font-weight-medium);
    transition: .2s;
    background-color: rgb(240, 240, 240);
    margin-top: 2rem;
}
.hero-button:hover{
    color: white;
    background-color: #506ff3;
}