.carousel-container {
    display: flex;
    flex-direction: row;
    height: 100%;
    width: 100%;
  }
  .carousel-container img {
    width: 10%;
  }
  .carousel-buttons {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 20%;
    gap: 2rem;
    transition: 1s;
    width: 40%;
    border-right: 2px solid;
    border-image: linear-gradient(90deg, #FBAD2A 0%, #FBDD32 100%) 1;
  }
  .carousel-buttons>p{
    font-size: 13px;
    color: #FCC15F;
    font-weight: bold;
    letter-spacing: 2px;
  }
  .carousel-buttons div {
    height: 20px;
    margin-bottom: 10px;
    border: none;
    cursor: pointer;
    display: flex;
    gap: .5rem;
    font-size: 24px;
    justify-content: center;
    align-items: center;
    color: white;
    width: 50%;
  }
  .carousel-buttons div>img{
    display: none;
  }
  .carousel-buttons div.active {
    justify-content: flex-end;
    color: #FCC15F;
    font-size: 38px;
    width: 60%;
  }
  .carousel-buttons div.active>img{
    display: block;
  }
  
  .carousel-content {
    display: flex;
    flex-direction: column;
    width: 90%;
    align-items: flex-start;
    padding-left: 7%;
    overflow-y: hidden;
    position: relative;
    color: white;
    font-size: 38px;
  }
  
  .carousel-item {
    width: 70%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 400px;
    opacity: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    transition: 1s; 
    padding: 0 1rem 0 0rem;
  }
  
  .carousel-item.active {
    opacity: 1;
  }
  

  @media all and (min-width:320px) and (max-width:990px){
    .carousel-container{
        display: none;
    }
  }