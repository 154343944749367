@import "../ThemeVariables.css";

#features-container {
    font-family: var(--font-family-inter);
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
    align-items: center;
    padding-bottom: 10%;
}

#features-container>.section-name {
    font: var(--font-style-normal) normal var(--font-weight-bold) 13px/16px var(--font-family-inter);
    color: var(--color-59c697);
    letter-spacing: 2px;
}

#features-container>.section-heading {
    text-align: center;
    width: 45%;
    font: var(--font-style-normal) normal var(--font-weight-bold) var(--font-size-24)/38px var(--font-family-inter);
    letter-spacing: var(--character-spacing-0);
    color: var(--color-191f23);
    margin-top: .3rem;
}

#features-container>.section-subHeading {
    text-align: center;
    height: 24px;
    font: var(--font-style-normal) normal var(--font-weight-normal) 20px/var(--line-spacing-30) var(--font-family-inter);
    margin-bottom: 2rem;
    letter-spacing: var(--character-spacing-0);
    color: var(--color-75797b);
}


/* Features list  */
#features-container>.features-list {
    display: grid;
    grid-template-columns: repeat(3, 25%);
    text-align: left;
    justify-content: center;
    gap: 3rem;
    /* margin-top: 4rem; */
}

#features-container>.features-list>.feature-content {
    /* margin: auto; */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: .7rem;
    position: relative;
    padding: 1rem 0;
}

#features-container>.features-list>.feature-content>img {
    /* margin-left: 1.5rem; */
    /* margin-bottom: 1rem; */
    width: 14%;
}

#features-container>.features-list>.feature-content>.title-content {
    display: flex;
    gap: 10px;
    font-size: 20px;
    font-weight: 600;
    align-items: flex-start;
    position: relative;
    margin-top: .5rem;
    width: 90%;
}

#features-container>.features-list>.feature-content>.title-content>img {
    position: absolute;
    /* top: 10%; */
    left: -7%;
}

#features-container>.features-list>.feature-content>.description-content {
    font: var(--font-style-normal) normal var(--font-weight-normal) var(--font-size-18)/var(--line-spacing-30) var(--font-family-inter);
    letter-spacing: var(--character-spacing-0);
    color: var(--color-75797b);
    /* margin-left: 1.5rem; */
}

#features-container>.features-list>.feature-content>.explore {
    /* margin-left: 1.5rem; */
    width: fit-content;
    display: flex;
    gap: 1rem;
    align-items: center;
    color: #506ff3;
    font-weight: 600;
}


@media all and (max-width: 816px){
    #features-container{
        /* margin-top: 12%; */
        padding: 2rem;
    }
    #features-container>.section-name{
        font-size: var(--font-size-14);
        font-style: 600;
    }
    #features-container>.section-heading{
        font-size: var(--font-size-22);
        width: 100%;
        font-weight: 800;
    }
    #features-container>.section-subHeading{
        font-size: var(--font-size-18);
    }
    #features-container>.features-list{
        /* border: 1px solid red; */
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 1rem;
        margin: auto;
        margin-top: 1rem;
        /* transform: translateX(2%); */
    }
    #features-container>.features-list>.feature-content{
        /* border: 1px solid red; */
    }
    #features-container>.features-list>.feature-content>.title-content{
        font-size: var(--font-size-24); 
        margin-top: 1rem; 
        /* line-height: 1.3; */
        /* border: 1px solid red; */
        position: relative;
    }
    #features-container>.features-list>.feature-content>.title-content>img{
        width: 5%;
        position: absolute;
        left: -7%;
        /* top: 25%; */
    }
    #features-container>.features-list>.feature-content>.description-content{
        margin-top: 1rem;
        /* margin-bottom: .7rem; */
        line-height: 1.6rem;
        font-size: var(--font-size-18);
    }
}