@import "../../ThemeVariables.css";
.feature-image{
    width: 50%;
    /* border: 1px solid blue; */
}

@media all and (min-width:320px) and (max-width: 990px){
    .feature-image{
        width: 100%;
        padding: 0 5%;
        /* border: 1px solid blue; */
    }   
}