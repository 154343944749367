@import "../ThemeVariables.css";

#navbar-container {
    width: 100%;
    display: flex;
    font-family: var(--font-family-inter);
    border-bottom: 2px solid;
    border-image: linear-gradient(90deg, #FBAD2A 0%, #FBDD32 100%) 1;
    position: relative;
    height: 12.5vh;
    opacity: 1;
    position: fixed;
    top: 0;
    z-index: 10;
    background-color: white;
}

#navbar-container>.left-section {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 0 2rem;
}

#navbar-container>.left-section>.logo-section {
    width: 9%;
}

#navbar-container>.left-section>.logo-section>img {
    width: 100%;
}

#navbar-container>.left-section>.menu-section {
    display: flex;
    gap: 47px;
    color: var(--color-52636f);
    font-size: var(--font-size-16);
    width: 60%;
    font-weight: var(--font-weight-medium);
}

#navbar-container>.left-section>.menu-section>p {
    cursor: pointer;
}

#navbar-container>.left-section>.contact-section {
    background-color: #E5E8E9;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    color: rgb(67, 67, 67);
    /* opacity: 0.5; */
    font-size: 16px;
    padding: 5px 20px;
    color: var(--color-52636f);
    font-weight: var(--font-weight-medium);
    transition: .5s;
}

#navbar-container>.left-section>.contact-section:hover {
    background-color:#506FF3;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--color-ffffff);
    border-radius: 5px;
    font-size: 16px;
    padding: 5px 20px;
    font-weight: var(--font-weight-medium);

}


/*rightsection */
#navbar-container>.right-section {
    width: 15%;
    display: flex;
    align-items: center;
    justify-content: center;
}

#navbar-container>.right-section>.chat-bot {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

#navbar-container>.right-section>.chat-bot>p {
    background-color: #191F23;
    width: 6rem;
    height: 1.7rem;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    font-size: var(--font-size-14);
}

.dropDown-section {
    position: absolute;
    background-color: white;
    width: 100%;
    z-index: 10;
    padding-top: 3rem;
    padding-bottom: 3.5rem;
    padding-left:2.5rem;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    font-size: var(--font-size-16);
    font-weight: 600;
    margin-top: 12.5vh;
    transition: 1s;
}

.dropDown-section>.menu-section {
    width: 63%;
    margin: auto;
    text-align: left;
    border: .5 px solid rgba(0, 0, 0, 0.3);
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.menu-section>p>a {
    text-decoration: none;
    color: gray;
}

#navbar-container>.hamburger-menu {
    display: none;
}

@media screen and (max-width: 990px) {
    #navbar-container>.hamburger-menu {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 100%;
        font-size: var(--font-size-14);
    }

    #navbar-container {
        display: block;
    }

    #navbar-container>.left-section,
    #navbar-container>.right-section {
        display: none;
    }

    .mobile-drop-down {
        font-family: var(--font-family-inter);
    }

}