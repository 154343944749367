@import "../ThemeVariables.css";

.DigitalCommerceImg-main-containers{
    background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    opacity: 1;
    margin:11%;
    font-family: var(--font-family-inter);
}

.feature-headings6{
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) var(--unnamed-font-size-24)/54px var(--unnamed-font-family-inter);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--unnamed-color-191f23);
    text-align: center;
    font: normal normal bold 24px/54px Inter;
    color: #191F23;
    opacity: 1;
    font-weight:700;
    margin-top:65px;
    margin-bottom:35px;
}

.DigitalCommerce-sub-cont0{
    width: 80%;
    margin-left:10%;
    display: grid;
    grid-template-columns: repeat(2,1fr);
    row-gap:0%;
    margin-bottom: 5%;
}

.DigitalCommerce-sub-cont0 > div{
   width: 80%;
}

.DigitalCommerce-sub-cont0-left-img{
   height:260px;
}

.featcher-left-main-cont0{
    border: 1px solid var(--unnamed-color-e6ebed);
    border: 1px solid #E6EBED;
    border-radius: 4px;
    opacity: 1;
    box-sizing: border-box;
    position: relative; 
    z-index: 1; 
    margin-left: -30%;
    margin-top:28px;
    border-left: none;
    padding-left: 150px;
    padding-top:40px;
    padding-right:10px;
    height: 205px;
    width: 100%;
}

.DigitalCommerce-sub-cont0-left{
    display: flex;
}

.featcher-plateform11{
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) 13px/16px var(--unnamed-font-family-inter);
    color: var(--unnamed-color-75797b);
    text-align: left;
    font: normal normal bold 13px/16px Inter;
    letter-spacing: 2.6px;
    color: #75797B;
    text-transform: uppercase;
    opacity: 1;
    margin-bottom: 10px;
    margin-right:120px;
}

.featcher-left-headings{
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-600) var(--unnamed-font-size-18)/28px var(--unnamed-font-family-inter);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--unnamed-color-506ff3);
    text-align: left;
    font: normal normal 600 18px/28px Inter;
    letter-spacing: 0px;
    color: #506FF3;
    opacity: 1;
    margin-bottom: 10px;
}

.ArrowPosition6{
    float: left;
    margin-top: -35px;
}

/* .DigitalCommerce-sub-cont0-left > img{
    width: 100%;
} */

.ArrowPosition6{
    margin-top: -1px;
}


/* media for mobile size 300-700 */
@media only screen and (max-width: 400px) and (min-width: 200px) {
    .feature-headings6{
        margin-top:17%;
        margin-bottom:10%;
    }
    
    .DigitalCommerce-sub-cont0{
        width: 100%;
        margin:0%;
        display: grid;
        grid-template-columns: repeat(1,1fr);
        row-gap:0%;
        margin-bottom:10%;
    }

    .DigitalCommerce-sub-cont0 > div{
        width: 100%;
        margin:auto;
     }
    
    .DigitalCommerce-sub-cont0-left{
        height:220px;
    }
    
    .DigitalCommerce-sub-cont0-left-img{
       width: 100%;
       height:100%;
       margin-top: 5px;
    }
    
    .featcher-left-main-cont0{
        border-left: none;
        padding-left:25%;
      margin-right: 25px;
      margin-left: -114px;
        width: 100%;
        height: 80%;
    }
        
 .featcher-plateform11{
    font-size:12px;
    padding:0;
    margin-bottom:10px;
    margin-right: 10px;
 }

 .featcher-left-headings{
    font-size:14px;
    line-height: 1.6;
 }
}
@media only screen and (max-width: 600px) and (min-width: 400px) {
    
    .feature-headings6{
        margin-top:17%;
        margin-bottom:10%;
    }
    
    .DigitalCommerce-sub-cont0{
        width: 100%;
        margin:0%;
        display: grid;
        grid-template-columns: repeat(1,1fr);
        row-gap:0%;
        margin-bottom:10%;
    }

    .DigitalCommerce-sub-cont0 > div{
        width: 90%;
        margin:auto;
     }
    
    .DigitalCommerce-sub-cont0-left{
        height:220px;
    }
    
    .DigitalCommerce-sub-cont0-left-img{
       width: 100%;
       height:100%;
       margin-top: 5px;
    }
    
    .featcher-left-main-cont0{
        border-left: none;
        padding-left:25%;
        padding-right:0%;
        width: 100%;
        height: 80%;
    }
        
 .featcher-plateform11{
    font-size:12px;
    padding:0;
    margin-bottom:10px;
    margin-right: 10px;
 }

 .featcher-left-headings{
    font-size:14px;
    line-height: 1.6;
 }
}



@media only screen and (max-width: 1300px) and (min-width: 1200px){
    .featcher-left-main-cont0{
        margin-left: -30%;
        padding-left: 125px;
        padding-top:40px;
        padding-right:5px;
    }
    /* .DigitalCommerce-sub-cont0 > div{
        width: 100%;
     }

     .DigitalCommerce-sub-cont0{
        margin-left: 2px;
     } */
    
}

@media only screen and (max-width: 1200px) and (min-width: 1100px){
    .featcher-left-main-cont0{
        margin-left: -29%;
        padding-left: 125px;
        padding-top:40px;
        padding-right:0px;
    }
    .DigitalCommerce-sub-cont0 > div{
        width: 100%;
     }
     
     /* .DigitalCommerce-sub-cont0{
        margin-left: 4%;
     } */
}

@media only screen and (max-width: 1100px) and (min-width: 1000px){
    .featcher-left-main-cont0{
        margin-left: -30%;
        padding-left: 135px;
        padding-top:40px;
        padding-right:2px;
    }
    .DigitalCommerce-sub-cont0 > div{
        width: 100%;
     }

     .DigitalCommerce-sub-cont0{
        width: 90%;
    }
    .DigitalCommerce-sub-cont0{
        margin-left: 3%;
     }
     
}
