@import "../../ThemeVariables.css";

.hero-heading{
    font-size: var(--font-size-69);
    font-weight: var(--font-weight-bold);
    line-height: 6rem;
}
.hero-heading>span{
    color: var(--color-fbad2a);
}


@media all and (min-width: 320px) and (max-width: 990px){
    .hero-heading{
        font-size: var(--font-size-28);
        line-height: 3rem;
        width: 60%;
    }
}