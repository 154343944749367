    #newsletter-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 109px;
    gap: 1rem;
    position: relative;
    background-color: #F1F4F8;
}

#newsletter-container>.section-title {
    letter-spacing: 2.6px;
    color: #1BD672;
    text-transform: uppercase;
    opacity: 1;
    text-align: left;
    font-size: var(--font-size-14);
    font-weight: var(--font-weight-bold);
}

#newsletter-container>.section-heading {
    font: var(--font-style-normal) normal var(--font-weight-bold) var(--font-size-24)/38px var(--font-family-inter);
    letter-spacing: var(--character-spacing-0);
    color: var(--color-191f23);
    font-size: var(--font-size-22);
    margin-bottom: 1rem;
    opacity: 0.9;
}

#newsletter-container>.form-section {
    display: flex;
    margin: auto;
    gap: 2.5rem;
    justify-content: space-around;
    width: 80%;
}

#newsletter-container>.form-section input {
    background: var(--color-ffffff) 0% 0% no-repeat padding-box;
    border: 1px solid var(--color-e6ebed);
    border-radius: 2px;
    opacity: 1;
    outline: none;
    padding: 1rem;
    width: 383px;
}

#newsletter-container>.form-section div input+p {
    text-align: left;
    font: var(--font-style-normal) normal var(--font-weight-normal) 12px/18px Rubik;
    letter-spacing: var(--character-spacing-0);
    color: var(--color-fa5a68);
    font-family: var(--font-family-inter);
    font-weight: 550;
}

#newsletter-container>.form-section input::-webkit-input-placeholder {
    color: #a1a1aa;
    font-weight: 550;
}

#newsletter-container>.permission-section {
    width: 70%;
    display: flex;
    gap: 2rem;
    text-align: left;
    padding-left: .5rem;
    
}

#newsletter-container>.permission-section>input[type="checkbox"] {
    accent-color: none;
    width: 25px;
    height: 25px;
}

#newsletter-container>.permission-section>p {
    font: var(--font-style-normal) normal var(--font-weight-normal) var(--font-size-14)/18px var(--font-family-inter);
    letter-spacing: var(--character-spacing-0);
    color: var(--color-75797b);
}

#newsletter-container>.permission-section>p>label {
    font: var(--font-style-normal) normal medium var(--font-size-14)/18px var(--font-family-inter);
    letter-spacing: var(--character-spacing-0);
    color: #5273EF;
}

#newsletter-container>.sign-me-up{
    width: 80%;
    position: relative;
}

#newsletter-container>.sign-me-up>button {
    float: right;
    padding: 1.5% 5%;
    font: var(--font-style-normal) normal var(--font-weight-600) 16px/var(--line-spacing-24) var(--font-family-inter);
    letter-spacing: var(--character-spacing-0);
    color: var(--color-a1a1aa);
    border-radius: 26px;
    border: none;
    cursor: pointer;
    background: rgb(48, 89, 230);
    background: linear-gradient(90deg, rgba(48, 89, 230, 1) 0%, rgba(112, 112, 231, 1) 51%);
    color: white;
}

@media all and (max-width: 990px) {
    #newsletter-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 10% 0;
    }
    #newsletter-container>.section-heading{
        text-align: center;
        padding: 1rem;
    }
    #newsletter-container>.form-section {
        display: flex;
        flex-direction: column;
        row-gap: .5rem;
        justify-content: center;
        align-items: center;
        width: 100%;
        padding: 0 1rem;
    }

    #newsletter-container>.form-section input{
        width: 320px;
        margin: auto;
    }

    #newsletter-container>.permission-section {
        width: 85%;
        display: flex;
        margin-top: 1rem;
        /* flex-direction: column; */
        justify-content: flex-start;
        align-items: flex-start;
    }
    #newsletter-container>.sign-me-up{
        display: flex;
        justify-content: center;
        margin-top: 1rem;
    }
    #newsletter-container>.sign-me-up>button {
        background: rgb(48, 89, 230);
        background: linear-gradient(90deg, rgba(48, 89, 230, 1) 0%, rgba(112, 112, 231, 1) 51%);
        width: auto;
        margin: auto;
        color: white;
        position: static;
        margin-bottom: 1rem;
        border: 1px solid blue;
    }
}