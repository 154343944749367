@import "../../styles/ThemeVariables.css";
#hero-section-container {
    margin-bottom: 7%;
    height: 100vh;
    display: flex;
    font-family: var(--font-family-inter);
    align-items: center;
    padding: 0 2rem;
    position: relative;
    padding-top: 10vh;
}

#hero-section-container>.left-section {
    width: 50%;
    padding-left: 2rem;
}

#hero-section-container>.navigate-arrow{
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 80%;
    color: #506FF3;
    background-color: white;
    border-radius: 50%;
    padding: 10px;
}

#hero-section-container>.left-section>.content-section {
    width: 100%;
}

#hero-section-container>.left-section>.content-section>p:first-child {
    font-size: 38px;
    text-align: left;
    font-weight: 700;
    color: #FFFFFF;
}

#hero-section-container>.left-section>.content-section>p:nth-child(2) {
    font: var(--font-style-normal) normal var(--font-weight-normal) 20px/var(--line-spacing-30) var(--font-family-inter);
    letter-spacing: var(--character-spacing-0);
    color:#FFFFFF;
    text-align: left;
    margin-top: 1rem;
    font-size: var(--font-size-20);
    font-weight:  var(--font-weight-medium);;
    width: 85%;
}

#hero-section-container>.left-section>.content-section>button {
    margin-top: 10%;
    float: left;
    width: 198px;
    height: 52px;
    border-radius: 26px;
    /* background: #506FF3 0% 0% no-repeat padding-box; */
    background-color: black;
    color: white;
    border: none;
    font-weight: 550;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 0 1rem;
}

#hero-section-container>.right-section {
    width: 50%;
    height: fit-content;
    background-position: top;
    float: left;
    padding-right: 16px;
}

#hero-section-container>.right-section>img {
    width: 100%;
}


@media all and (max-width:812px){
    #hero-section-container{
        display: flex;
        height: auto;
        flex-direction: column-reverse;
        background-image: url("../../images/heroSection/Comb.svg");
        background-size: contain;
        background-position: left center;
        margin-top: 10vh;
        padding-bottom: 2rem;
    }
    #hero-section-container>.left-section{
        padding: 0;
        width: fit-content;
        margin: auto;
        margin: 1rem 0rem;
    }
    #hero-section-container>.left-section>.content-section {
        /* width: fit-content; */
        width: 100%;
    }
    #hero-section-container>.navigate-arrow{
        display: none;
    }
    #hero-section-container>.left-section>.content-section>p:first-child {
        font-size: 24px;
    }
    
    #hero-section-container>.left-section>.content-section>p:nth-child(2) {
        font-size: 18px;
        color: white;
        width: 100%;
        margin-top: 1rem;
    }
    
    #hero-section-container>.left-section>.content-section>button {
        margin-top: 2rem;
    }
    
    #hero-section-container>.right-section{
        width: 115%;
        padding: 0rem 7%;
        margin-top: 0%;
        margin-left: 0;
        margin-right: 0;
    }
    #hero-section-container>.right-section>img{
        width: 100%;
    }
}