@import "../../../../assets/styles/ThemeVariables.css";

.profile-container {
    margin: 0% 0 25% 0;
}

.profile-container>.details>div{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 1rem;
}

.profile-container>.details>div>p:first-child {
    font-size: var(--font-size-22);
    font-weight: var(--font-weight-600);
}

.profile-container>.details * {
    margin-bottom: 10px;
}

.profile-container>.details>div>p:nth-child(2) {
    font-size: var(--font-size-13);
    font-weight: var(--font-weight-600);
    ;
    color: var(--color-52636f);
}

.profile-container>.details>a>button {
    background-color: var(--color-3f60f5);
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 2% 3%;
    border-radius: 20px;
    color: var(--color-ffffff);
    font-weight: 500;
}

.profile-container>.description {
    margin-top: 1rem;
    font-size: var(--font-size-16);
    color: var(--color-52636f);
}

.profile-container>.read-more>a {
    display: flex;
    align-items: center;
    gap: 10px;
    color: #3f60f5;
    font-size: var(--font-size-14);
    font-weight: var(--font-weight-600);
    margin-top: 7%;
}


@media all and (min-width:320px) and (max-width:990px) {
    .profile-container {
        /* margin: 0% 0 25% 0; */
    }

    .profile-container>.details>p:first-child {
        font-size: var(--font-size-22);
        font-weight: var(--font-weight-600);
        margin-top: 1rem;
    }

    .profile-container>.details>* {
        margin-bottom: 5px;
    }

    .profile-container>.details>p:nth-child(2) {
        font-size: var(--font-size-13);
        font-weight: var(--font-weight-600);
        ;
        color: var(--color-52636f);
    }

    .profile-container>.details>a>button {
        background-color: var(--color-506ff3);
        display: flex;
        align-items: center;
        gap: 10px;
        padding: 2% 3%;
        border-radius: 20px;
        color: var(--color-ffffff);
        font-weight: 500;
    }

    .profile-container>.description {
        margin-top: 1rem;
        font-size: var(--font-size-16);
        color: var(--color-52636f);
    }

    .profile-container>.read-more>a {
        display: flex;
        align-items: center;
        gap: 10px;
        color: var(--color-506ff3);
        font-size: var(--font-size-14);
        font-weight: var(--font-weight-600);
    }
}