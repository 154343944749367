@import "../../ThemeVariables.css";

.special-section-container{
    background-color: #DAC5FA;
}
.special-section-container>.left-section{
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: "#DAC5FA";
}

.special-section-container>.right-section{
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #191f23;
}

@media all and (min-width: 320px) and (max-width: 990px){
    .special-section-container{
        background-color: white;
    }
    .special-section-container>.left-section{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: white;
    }
    
    .special-section-container>.right-section{
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        justify-content: center;
        background-color: white;
    }
}