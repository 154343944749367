.Footer_container {
    background-color: #253D61;
    padding: 10%;
    padding-top: 100px;
    display: flex;
    justify-content: space-center;
    gap: 4rem;
    font-family: var(--font-family-inter);
    border-bottom:1px solid gray;
}

.Footer_lower {
    background-color: #1F3658;
    height: 93px;
    opacity: 1;
    align-items: center;
    display: flex;
    justify-content: space-around;
}

.social_icon_cont {
    width: 42%;
}

.social_icon {
    width: 39px;
    height: 39px;
    margin-left: 20px;
}

.footer_bottom_links {
    width: 58%;
    font-size: 16px;
    color: #A1A8B3;
    display: flex;
    justify-content: space-evenly;
}

.footer_list_heading {
    font-size: 20px;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
    border-left: 1px solid #F9AC3C;
    padding-left: 15px;
    margin-bottom: 25px;
}

.footer_section {
    text-align: left;
}

.footer_list_sub_heading {
    font-size: 16px;
    color: #A1A8B3;
    opacity: 1;
    line-height: 3.8;
}

/* Footer Background  */
.demo-container {
    background: transparent linear-gradient(269deg, #6C86EA3B 0%, #5273EF 100%) 0% 0% no-repeat padding-box;
    text-align: left;
    padding: 7% 10%;
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.demo-container>.section-title {
    font: var(--font-style-normal) normal var(--font-weight-bold) 13px/16px var(--font-family-inter);
    font-size: var(--font-size-14);
    color: var(--color-ffffff);
    margin-left: 2%;
}

.demo-container>div {
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}

.demo-container>div>img {
    position: absolute;
    left: -3%;
    top: 12%;
    width: 1.7%;
}

.demo-container>div>p {
    font-size: 45px;
    font-weight: 700;
    letter-spacing: var(--character-spacing-0);
    color: var(--color-ffffff);
    margin-left: 1rem;
    line-height: 1.4;
}

.demo-container>button {
    margin-left: 1.7%;
    padding: .8rem .7rem;
    border: 1px solid black;
    background: var(--color-191f23) 0% 0% no-repeat padding-box;
    background: #191F23 0% 0% no-repeat padding-box;
    border-radius: 26px;
    opacity: 1;
    font: var(--font-style-normal) normal var(--font-weight-600) 18px/var(--line-spacing-24) var(--font-family-inter);
    letter-spacing: var(--character-spacing-0);
    color: var(--color-f1f4f8);
    width: 20%;
}

.social_icon_img{
    display: flex ;
    flex-direction: row;
}



@media screen and (max-width: 600px) {
   
     .footer_section{
        display: none;
     }
     .Footer_container{
        display:flex;
        flex-direction: column;
     }
     .Footer_lower{
        height: auto;
        flex-direction: column-reverse;
    }
    .footer_bottom_links{
        flex-direction: column;
        font-size: 20px;
        width: 100%;
        margin-top: 30px;
        margin-bottom: 40px;
    }
    .footer-link-title{
        text-align: left;
        padding: 6px;
        margin-left:30px;
    }
    .social_icon_img{
        margin-bottom: 60px;
    }
    .demo-container {
        background: transparent linear-gradient(269deg, #6C86EA3B 0%, #5273EF 100%) 0% 0% no-repeat padding-box;
        text-align: left;
        /* padding: 7% 10%; */
    }
    
    .demo-container>.section-title {
        font: var(--font-style-normal) normal var(--font-weight-bold) 13px/16px var(--font-family-inter);
        font-size: 12px;
        color: var(--color-ffffff);
    }
    
    .demo-container>div {
        position: relative;
        display: flex;
        align-items: flex-start;
    }
    
    .demo-container>div>img {
        position: absolute;
        width: 4%;
        top: 17%;
    }
    
    .demo-container>div>p {
        font-size: var(--font-size-14);
        letter-spacing: var(--character-spacing-0);
        color: var(--color-ffffff);
    }
    
    .demo-container>button {
        width: 50%;
        border: 1px solid black;
        background: var(--color-191f23) 0% 0% no-repeat padding-box;
        background: #191F23 0% 0% no-repeat padding-box;
        border-radius: 26px;
        opacity: 1;
        font: var(--font-style-normal) normal var(--font-weight-600) 16px/var(--line-spacing-24) var(--font-family-inter);
        letter-spacing: var(--character-spacing-0);
        color: var(--color-f1f4f8);
        padding: 1rem 1.5rem;
        font-size: var(--font-size-18);
    }

    .footer_list_sub_heading {
        line-height: 2.5;
    }
}







@media all and (min-width: 600px){

    .footer-for-mobile{
        display: none;
    }
}

@media screen and (max-width: 990px) {
   
    .demo-container *{
        
        margin: .4rem 0;
        padding: 0;
    }
    .demo-container>.section-title{
        margin-left: 5%;
    }
    .demo-container>div{
        position: relative;
    }
    .demo-container>div>img{
        width: 4%;
        left: -2%;
        top: 10%;
    }
    .demo-container>div>p {
        
        font-size: 20px ;
        letter-spacing: var(--character-spacing-0);
        color: var(--color-ffffff);
    }
    .demo-container>button{
        padding: .4rem .8rem;
        margin-left: 5%;
        font-size: 12px;
        font-weight: 600;
    }
}