.readmore{
   display: flex;
   align-items: center;
   gap: 1rem;
   color: #506ff3;
   font-weight: 600; 
}

@media all and (min-width: 320px) and (max-width: 990px){

}