.title-content-container {
    width: 80%;
    margin: auto;
}

.title-content-container>p:first-child {
    font-size: var(--font-size-13);
    font-weight: var(--font-weight-bold);
    margin: 10% 0 2.5% 0;
    letter-spacing: 2.6px;
}

.title-content-container>p:last-child {
    width: 80%;
    font-size: var(--font-size-38);
    font-weight: var(--font-weight-medium);
    color: var(--color-191f23);
    margin: 2.5% 0 10% 0;
}


@media all and (min-width:320px) and (max-width:990px) {
    .title-content-container {
        width: 80%;
        margin: auto;
    }

    .title-content-container>p:first-child {
        font-size: var(--font-size-13);
        font-weight: var(--font-weight-bold);
        margin: 20% 0 10% 0;
        letter-spacing: 2.6px;
    }

    .title-content-container>p:last-child {
        width: 100%;
        font-size: var(--font-size-24);
        font-weight: var(--font-weight-medium);
        color: var(--color-191f23);
        margin: 2.5% 0 10% 0;
    }
}